import React from "react";

const Home = (props) => {
  return (
    <React.Fragment>
      <section id="features" className="features">
        <h2>Features</h2>
        <div className="feature-cards">
          <div className="card">
            <h3>Top Listing</h3>
            <p>Always appear at the top of the token list on Pump.fun.</p>
          </div>
          <div className="card">
            <h3>Volume Boost</h3>
            <p>Increase your token's trading volume with our advanced bots.</p>
          </div>
          <div className="card">
            <h3>Trending Domination</h3>
            <p>Dominate the Photon trending charts across all time spans.</p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
