import React from "react";
import { Link } from "react-router-dom";

const Hero = (props) => {
  return (
    <React.Fragment>
      <section className="hero">
        <div className="hero-content">
          <h1>Welcome to AP3X BumperBot</h1>
          <p>
            The fastest way to boost your token's visibility and volume on the
            Solana blockchain.
          </p>
          <Link to="register" className="cta-button">
            Get Started
          </Link>
        </div>
        <div className="hero-image">
          <img
            src="../assets/images/home/laptop.png"
            className="img-fluid laptop-img"
            alt="laptop"
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Hero;
