import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <React.Fragment>
      <nav className="navbarc">
        <div className="logo">
          <img src="../assets/images/logo.png" alt="AP3X BumperBot Logo" />
        </div>
        <ul className="navc-links d-none d-md-block">
          <li>
            <a href="#features">Features</a>
          </li>
          <li>
            <a href="#pricing">Pricing</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <Link to="login" className="login-button">
          <span>Login </span>
        </Link>
      </nav>
    </React.Fragment>
  );
};

export default Header;
