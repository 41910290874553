import React from "react";

const StarIcon = (props) => {
  return (
    <React.Fragment>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6601 22.6703C17.1301 22.6703 16.4501 22.5003 15.6001 22.0003L12.6101 20.2303C12.3001 20.0503 11.7001 20.0503 11.4001 20.2303L8.40012 22.0003C6.63012 23.0503 5.59012 22.6303 5.12012 22.2903C4.66012 21.9503 3.94012 21.0803 4.41012 19.0803L5.12012 16.0103C5.20012 15.6903 5.04012 15.1403 4.80012 14.9003L2.32012 12.4203C1.08012 11.1803 1.18012 10.1203 1.35012 9.60031C1.52012 9.08031 2.06012 8.16031 3.78012 7.87031L6.97012 7.34031C7.27012 7.29031 7.70012 6.97031 7.83012 6.70031L9.60012 3.17031C10.4001 1.56031 11.4501 1.32031 12.0001 1.32031C12.5501 1.32031 13.6001 1.56031 14.4001 3.17031L16.1601 6.69031C16.3001 6.96031 16.7301 7.28031 17.0301 7.33031L20.2201 7.86031C21.9501 8.15031 22.4901 9.07031 22.6501 9.59031C22.8101 10.1103 22.9101 11.1703 21.6801 12.4103L19.2001 14.9003C18.9601 15.1403 18.8101 15.6803 18.8801 16.0103L19.5901 19.0803C20.0501 21.0803 19.3401 21.9503 18.8801 22.2903C18.6301 22.4703 18.2301 22.6703 17.6601 22.6703ZM12.0001 18.5903C12.4901 18.5903 12.9801 18.7103 13.3701 18.9403L16.3601 20.7103C17.2301 21.2303 17.7801 21.2303 17.9901 21.0803C18.2001 20.9303 18.3501 20.4003 18.1301 19.4203L17.4201 16.3503C17.2301 15.5203 17.5401 14.4503 18.1401 13.8403L20.6201 11.3603C21.1101 10.8703 21.3301 10.3903 21.2301 10.0603C21.1201 9.73031 20.6601 9.46031 19.9801 9.35031L16.7901 8.82031C16.0201 8.69031 15.1801 8.07031 14.8301 7.37031L13.0701 3.85031C12.7501 3.21031 12.3501 2.83031 12.0001 2.83031C11.6501 2.83031 11.2501 3.21031 10.9401 3.85031L9.17012 7.37031C8.82012 8.07031 7.98012 8.69031 7.21012 8.82031L4.03012 9.35031C3.35012 9.46031 2.89012 9.73031 2.78012 10.0603C2.67012 10.3903 2.90012 10.8803 3.39012 11.3603L5.87012 13.8403C6.47012 14.4403 6.78012 15.5203 6.59012 16.3503L5.88012 19.4203C5.65012 20.4103 5.81012 20.9303 6.02012 21.0803C6.23012 21.2303 6.77012 21.2203 7.65012 20.7103L10.6401 18.9403C11.0201 18.7103 11.5101 18.5903 12.0001 18.5903Z"
          fill="#292D32"
        ></path>
      </svg>
    </React.Fragment>
  );
};

export default StarIcon;
