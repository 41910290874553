import React from "react";

import { Link } from "react-router-dom";

import Menu from "./Menu";
import ToggleIcon from "../../component/Icons/ToggleIcon";
import CloseIcon from "../../component/Icons/CloseIcon";

const NavBar = (props) => {
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-md p-0">
        <button
          className="navbar-toggler d-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainnavbarNav"
          aria-controls="mainnavbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <ToggleIcon />
        </button>
        <div className="collapse navbar-collapse" id="mainnavbarNav">
          <div className="menu-panel">
            <button
              data-bs-toggle="collapse"
              data-bs-target="#mainnavbarNav"
              className="mainnav-close d-block d-md-none"
            >
              <CloseIcon />
            </button>
            <Link to="/token" className="logo-icon d-none d-md-flex">
              <img
                src="../assets/svg/logo-icon.svg"
                className="img-fluid"
                width="44px"
              />
            </Link>
            <Menu />
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
