import React, { useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuthAPI } from "../../hooks/useAuthAPI";

import MailIcon from "../../component/Icons/MailIcon";
import LockIcon from "../../component/Icons/LockIcon";
import UserIcon from "../../component/Icons/UserIcon";
import Loading from "../../component/Loading/Loading";
import SettingIcon from "../../component/Icons/SettingIcon";

const Register = (props) => {
  const { signup } = useAuthAPI();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadingStatus, setLoadingStatus] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [newPasswd, setNewPasswd] = useState("");
  const [confNewPasswd, setConfNewPasswd] = useState("");
  const [referral, setReferral] = useState(searchParams.get("inviteCode"));

  const onSignup = async () => {
    if (name === "") {
      toast.warning("Please input username!");
      return;
    }
    if (email === "") {
      toast.warning("Please input email!");
      return;
    }
    if (newPasswd === "") {
      toast.warning("Please input password!");
      return;
    }
    if (confNewPasswd === "") {
      toast.warning("Please confirm password!");
      return;
    }
    if (newPasswd !== confNewPasswd) {
      toast.warning("Password mismatch!");
      return;
    }

    setLoadingStatus(true);
    const res = await signup(name, email, newPasswd, referral);

    if (res.status === true) {
      toast.success("Sign up succeed");
      navigate("/login");
    } else {
      toast.error(res.error);
    }
    setLoadingStatus(false);
  };

  return (
    <React.Fragment>
      <section className="login-section section-scroll">
        <div className="text-center mb-5">
          <Link to="/">
            <img src="../assets/images/logo.png" height="50px" />
          </Link>
        </div>
        <div className="row m-0">
          <div className="col-xxl-4 col-lg-5 mx-auto p-0">
            <div className="login-box">
              <div>
                <h2>
                  Welcome to <span>AP3X Bot !</span>
                </h2>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <Link className="nav-link" to="/login">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="signup-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#signup-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="signup-tab-pane"
                      aria-selected="false"
                    >
                      Signup
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="signup-tab-pane"
                    role="tabpanel"
                    aria-labelledby="signup-tab"
                    tabIndex="0"
                  >
                    <div className="auth-form">
                      <div className="mb-3 form-group">
                        <i className="iconsax" data-icon="user-1">
                          <UserIcon />
                        </i>
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="name"
                          placeholder="Enter your name"
                          className="form-control"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 form-group">
                        <i className="iconsax" data-icon="mail">
                          <MailIcon />
                        </i>
                        <label htmlFor="emailid" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          placeholder="Enter your mail id"
                          className="form-control"
                          id="emailid"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 form-group">
                        <i className="iconsax" data-icon="lock-2">
                          <LockIcon />
                        </i>
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          placeholder="Enter your password"
                          type="password"
                          className="form-control"
                          id="password"
                          value={newPasswd}
                          onChange={(e) => setNewPasswd(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 form-group">
                        <i className="iconsax" data-icon="lock-2">
                          <LockIcon />
                        </i>
                        <label htmlFor="password1" className="form-label">
                          Confirm Password
                        </label>
                        <input
                          placeholder="Enter your password"
                          type="password"
                          className="form-control"
                          id="password1"
                          value={confNewPasswd}
                          onChange={(e) => setConfNewPasswd(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 form-group">
                        <i className="iconsax" data-icon="lock-2">
                          <SettingIcon />
                        </i>
                        <label htmlFor="referral" className="form-label">
                          Referral Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="No Referral Code"
                          id="referral"
                          value={referral}
                          disabled
                        />
                      </div>
                      <button
                        data-cursor="pointer"
                        className="btn-solid w-100 text-center mt-4"
                        onClick={onSignup}
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loadingStatus && <Loading />}
    </React.Fragment>
  );
};

export default Register;
