import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import { authService } from "../services";

export const useAuthAPI = () => {
  const signup = async (username, email, password, referral) => {
    const res = await authService.signup(username, email, password, referral);
    return res;
  };

  const reset = async (email) => {
    const res = await authService.reset(email);
    return res;
  };

  const login = async (username, password) => {
    const res = await authService.login(username, password);

    if (res.status === true) {
      const decodedToken = jwtDecode(res.token.replace("Bearer ", ""));
      localStorage.setItem("token", res.token);

      Cookies.set(
        "currentUser",
        JSON.stringify({
          userId: decodedToken.userId,
          username: decodedToken.username,
        })
      );
    }
    return res;
  };

  const logout = () => {
    localStorage.removeItem("token");
  };

  return { login, logout, signup, reset };
};
