import React from "react";

const FaqItem = (props) => {
  const { title, content, id } = props;

  return (
    <React.Fragment>
      <div
        className="accordion-item aos-init aos-animate"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <h2 className="accordion-header" id={`panelsStayOpen-heading${id}`}>
          <button
            data-cursor="pointer"
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#panelsStayOpen-collapse${id}`}
            aria-expanded="false"
            aria-controls={`panelsStayOpen-collapse${id}`}
          >
            {title}
          </button>
        </h2>
        <div
          id={`panelsStayOpen-collapse${id}`}
          className="accordion-collapse collapse"
          aria-labelledby={`panelsStayOpen-heading${id}`}
        >
          <div className="accordion-body">
            <p>{content}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FaqItem;
