import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

const MainLayout = (props) => {
  return (
    <React.Fragment>
      <Header />
      <div className="main-content">
        <Outlet />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MainLayout;
