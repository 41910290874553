import React, { useMemo } from "react";
import queryString from "query-string";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

import SubscriptionIcon from "../../component/Icons/SubscriptionIcon";
import HelpIcon from "../../component/Icons/HelpIcon";
import StarIcon from "../../component/Icons/StarIcon";
import SettingIcon from "../../component/Icons/SettingIcon";
import LogOutIcon from "../../component/Icons/LogOutIcon";
import SearchIcon from "../../component/Icons/SearchIcon";

export function useRouter() {
  const params = useParams();
  const location = useLocation();

  return useMemo(() => {
    return {
      push: location.push,
      replace: location.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      location,
    };
  }, [params, location]);
}

const Menu = (props) => {
  const router = useRouter();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("");
  };

  return (
    <React.Fragment>
      <ul className="nav nav-tabs menu-wrapper" id="myTab" role="tablist">
        <li>
          <Link
            className={`nav-link ${
              router.pathname === "/token" ? "active" : ""
            }`}
            to="token"
          >
            <i className="iconsax">
              <SearchIcon />
            </i>
            <span>Tokens</span>{" "}
          </Link>
        </li>
        <li>
          <Link
            className={`nav-link ${
              router.pathname === "/referral" ? "active" : ""
            }`}
            to="referral"
          >
            <i className="iconsax">
              <SubscriptionIcon />
            </i>
            <span>Referral</span>{" "}
          </Link>
        </li>
        <li>
          <Link
            className={`nav-link ${
              router.pathname === "/point" ? "active" : ""
            }`}
            to="point"
          >
            <i className="iconsax">
              <StarIcon />
            </i>
            <span>Point</span>{" "}
          </Link>
        </li>
        <li>
          <Link
            className={`nav-link ${
              router.pathname === "/help" ? "active" : ""
            }`}
            to="help"
          >
            <i className="iconsax">
              <HelpIcon />
            </i>
            <span>Help</span>{" "}
          </Link>
        </li>
        <li>
          <Link
            className={`nav-link ${
              router.pathname === "/setting" ? "active" : ""
            }`}
            to="setting"
          >
            <i className="iconsax">
              <SettingIcon />
            </i>
            <span>Setting</span>
          </Link>
        </li>
        <li>
          <button className="nav-link" onClick={logout}>
            <i className="iconsax">
              <LogOutIcon />
            </i>
            <span>Log Out</span>
          </button>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Menu;
