import React from "react";
import { toast } from "react-toastify";

import { useWalletAPI } from "../../hooks/useWalletAPI";

import AddIcon from "../Icons/AddIcon";

const NewWalletCard = (props) => {
  const { tokenAddr, onAddNewWallet } = props;

  const { addNewWallet } = useWalletAPI();

  const onAddWallet = async () => {
    const res = await addNewWallet(tokenAddr);
    if (res.status === true) {
      onAddNewWallet();
      toast.success("Bump wallet creation succeed!");
    } else {
      toast.error(res.error);
    }
  };

  return (
    <React.Fragment>
      <div className="bump-card new-wallet text-white" onClick={onAddWallet}>
        <AddIcon />
      </div>
    </React.Fragment>
  );
};

export default NewWalletCard;
