import React, { useEffect, useState } from "react";

import { useUserAPI } from "../../hooks/useUserAPI";
import { trimAddr } from "../../hooks/common/address";

import CopyIcon from "../Icons/CopyIcon";
import WalletIcon from "../Icons/WalletIcon";
import ReloadIcon from "../Icons/ReloadIcon";

const Balance = (props) => {
  const { getWalletData } = useUserAPI();

  const [walletData, setWalletData] = useState(null);

  const fetchBalance = async () => {
    const res = await getWalletData();
    if (res.status === true) setWalletData(res);
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-around gap-5 mt-3 mt-md-0">
        <div
          className="d-flex align-items-center text-white gap-2"
          onClick={() => {
            navigator.clipboard.writeText(walletData.address);
          }}
        >
          {/* <WalletIcon /> */}
          <span>
            {walletData !== null ? trimAddr(walletData.address) : "---"}
          </span>
        </div>
        <div
          className="d-flex align-items-center text-white gap-2"
          onClick={fetchBalance}
        >
          {/* <img src="assets/images/sol-logo.webp" /> */}
          <span>
            {walletData !== null ? walletData.balance + " SOL" : "---"}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Balance;
