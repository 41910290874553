import axios from "axios";

export class UserService {
  constructor(url) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Time out!",
    });

    // A request interceptor to include the auth token in every request
    this.instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = token;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  info = () => {
    return this.instance.get("/info", {}).then((res) => {
      return res.data;
    });
  };

  referral = () => {
    return this.instance.get("/referral", {}).then((res) => {
      return res.data;
    });
  };

  point = () => {
    return this.instance.get("/point", {}).then((res) => {
      return res.data;
    });
  };

  balance = () => {
    return this.instance.get("/sol_balance", {}).then((res) => {
      return res.data;
    });
  };

  update = (uInfo) => {
    return this.instance.post("/update", uInfo).then((res) => {
      return res.data;
    });
  };

  withdraw = () => {
    return this.instance.post("/withdraw").then((res) => {
      return res.data;
    });
  };

  withdrawOut = (wInfo) => {
    return this.instance.post("/withdraw_out", wInfo).then((res) => {
      return res.data;
    });
  };
}
