import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuthAPI } from "../../hooks/useAuthAPI";

import LockIcon from "../../component/Icons/LockIcon";
import UserIcon from "../../component/Icons/UserIcon";
import Loading from "../../component/Loading/Loading";

const Reset = (props) => {
  const navigate = useNavigate();

  const { login } = useAuthAPI();

  const loginRef = useRef(null);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = async () => {
    if (username === "") {
      toast.warning("Please input username!");
      return;
    }
    if (password === "") {
      toast.warning("Please input password!");
      return;
    }

    setLoadingStatus(true);
    const res = await login(username, password);

    if (res.status === true) {
      toast.success("Log in succeed");
      navigate("/token");
    } else {
      toast.error(res.error);
    }
    setLoadingStatus(false);
  };

  return (
    <React.Fragment>
      <section className="login-section section-scroll">
        <div className="text-center mb-5">
          <Link to="/">
            <img src="../assets/images/logo.png" height="50px" />
          </Link>
        </div>
        <div className="row m-0">
          <div className="col-xxl-4 col-lg-5 mx-auto p-0">
            <div className="login-box">
              <div>
                <h2>
                  Welcome to <span>AP3X Bot !</span>
                </h2>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      ref={loginRef}
                      className="nav-link active"
                      id="login-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#login-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="login-tab-pane"
                      aria-selected="true"
                    >
                      Login
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link className="nav-link" to="/register">
                      Signup
                    </Link>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabIndex="0"
                  >
                    <div className="auth-form">
                      <div className="mb-3 form-group">
                        <i className="iconsax" data-icon="mail">
                          <UserIcon />
                        </i>
                        <label htmlFor="username" className="form-label">
                          Username | Email
                        </label>
                        <input
                          type="text"
                          placeholder="Enter your username or email"
                          className="form-control"
                          id="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="mb-2 form-group">
                        <i className="iconsax" data-icon="lock-2">
                          <LockIcon />
                        </i>
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          placeholder="Enter your password"
                          type="password"
                          className="form-control"
                          id="password"
                          vlaue={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="text-end">
                        <Link data-cursor="pointer" to="/reset">
                          Forget Password?
                        </Link>
                      </div>
                      <button
                        data-cursor="pointer"
                        className="btn-solid w-100 text-center mt-3"
                        onClick={onLogin}
                      >
                        Log me in
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loadingStatus && <Loading />}
    </React.Fragment>
  );
};

export default Reset;
