import { Link } from "react-router-dom";

import Balance from "../../component/Balance/Balance";
import FaqItem from "../../component/FaqItem/FaqItem";

import ToggleIcon from "../../component/Icons/ToggleIcon";

const Help = (props) => {
  return (
    <div className="main-wrapper p-0">
      <div className="fixed-header">
        <div className="d-flex align-items-center gap-2">
          <button
            className="navbar-toggler d-md-none d-block"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainnavbarNav"
            aria-controls="mainnavbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify">
              <ToggleIcon />
            </i>
          </button>
          <Link to="/token" className="logo-icon d-flex d-md-none">
            <img
              src="../assets/svg/logo-icon.svg"
              className="img-fluid"
              width="44px"
            />
          </Link>
          <h3>Help</h3>
        </div>
      </div>
      <div className="faq-section main-section section-scroll">
        <div className="container card p-0">
          <div className="card-header">
            <h3
              className="text-white title-basic aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              FAQ
            </h3>
          </div>
          <div className="card-body px-sm-4 px-3">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <FaqItem
                title="What is bump bot?"
                content="Bump bot is a bot which buys/sells tokens automatically, thus bumps the token to the first page of Pump.fun"
                id="1"
              />
              <FaqItem
                title="How does referral system work?"
                content="Referrer gets paid from referree. When a referrer adds a token to bump, 20% of fee (0.02 SOL) is transmitted to the referrer."
                id="2"
              />
              <FaqItem
                title="How to get point and what is its usage?"
                content="Users can get points in 3 ways. 
                1. Referrer gets 100 points.
                2. When the user adds a token to bump, gets 30 points.
                3. When the user bumps a token, gets 10 points."
                id="3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
