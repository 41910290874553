import React from "react";

const About = (props) => {
  return (
    <React.Fragment>
      <section id="about" className="about">
        <h2>About Us</h2>
        <p>
          AP3X BumperBot is dedicated to helping your token gain the visibility
          it deserves on the Solana blockchain. Trusted by thousands of users,
          our bots are designed to give your project the competitive edge it
          needs.
        </p>
      </section>
    </React.Fragment>
  );
};

export default About;
