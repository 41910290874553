import axios from "axios";

export class TokenService {
  constructor(url) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Time out!",
    });

    // A request interceptor to include the auth token in every request
    this.instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = token;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  add = (tokenMint) => {
    return this.instance
      .post("/add", {
        tokenMint: tokenMint,
      })
      .then((res) => {
        return res.data;
      });
  };

  list = (keyword) => {
    return this.instance
      .get("/list", {
        keyword,
      })
      .then((res) => {
        return res.data;
      });
  };

  detail = (tokenMint) => {
    return this.instance
      .get("/detail", {
        params: {
          tokenMint: tokenMint,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

  startBump = (tokenMint) => {
    return this.instance
      .post("/start_bump_all", {
        tokenMint: tokenMint,
      })
      .then((res) => {
        return res.data;
      });
  };

  stopBump = (tokenMint) => {
    return this.instance
      .post("/stop_bump_all", {
        tokenMint: tokenMint,
      })
      .then((res) => {
        return res.data;
      });
  };
}
