import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MainLayout from "./layout/main/MainLayout";
import BoardLayout from "./layout/board/BoardLayout";

import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Reset from "./pages/Reset/Reset";

import Token from "./pages/Token/Token";
import Point from "./pages/Point/Point";
import Referral from "./pages/Referral/Referral";
import Help from "./pages/Help/Help";
import Setting from "./pages/Setting/Setting";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="reset" element={<Reset />} />
          <Route element={<BoardLayout />}>
            <Route path="token" element={<Token />} />
            <Route path="point" element={<Point />} />
            <Route path="referral" element={<Referral />} />
            <Route path="help" element={<Help />} />
            <Route path="setting" element={<Setting />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
}

export default App;
