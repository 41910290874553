import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { trimAddr } from "../../hooks/common/address";
import { useWalletAPI } from "../../hooks/useWalletAPI";

const BumpCard = (props) => {
  const { wallet } = props;

  const { startBump, stopBump } = useWalletAPI();

  const [bumpStatus, setBumpStatus] = useState(wallet.bumping);
  const [amount, setAmount] = useState(wallet.bumpAmount);
  const [gap, setGap] = useState(wallet.bumpGap / 1000);

  const onStartBump = async () => {
    const res = await startBump(
      wallet.tokenId.mintAddr,
      wallet.address,
      gap * 1000,
      amount
    );

    if (res.status === true) {
      setBumpStatus(true);
      toast.success("Bump started successfully!");
    } else {
      toast.error(res.error);
    }
  };

  const onStopBump = async () => {
    const res = await stopBump(wallet.tokenId.mintAddr, wallet.address);

    if (res.status === true) {
      setBumpStatus(false);
      toast.success("Bump stopped!");
    } else {
      toast.error(res.error);
    }
  };

  useEffect(() => {
    setBumpStatus(wallet.bumping);
  }, [wallet]);

  return (
    <React.Fragment>
      <div className="card bump-card text-white ">
        <div className="card-body">
          <h5 className="card-title mb-3">
            <span className="me-1">Wallet : {trimAddr(wallet.address)}</span>
          </h5>
          <div className="mb-2">
            <input
              type="number"
              className="form-control"
              placeholder="Bump Amount"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </div>
          <div className="mb-2">
            <input
              type="number"
              className="form-control"
              placeholder="Bump Gap"
              value={gap}
              onChange={(e) => {
                setGap(e.target.value);
              }}
            />
          </div>
          {bumpStatus === false ? (
            <button
              className="w-100 btn btn-theme btn-start d-inline-block"
              onClick={onStartBump}
            >
              Start
            </button>
          ) : (
            <button
              className="w-100 btn btn-theme btn-stop d-inline-block"
              onClick={onStopBump}
            >
              Stop
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BumpCard;
