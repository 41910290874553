import { tokenService } from "../services";

export const useTokenAPI = () => {
  const addNewToken = async (addr) => {
    const res = await tokenService.add(addr);
    return res;
  };

  const getTokenList = async (keyword) => {
    const res = await tokenService.list(keyword);

    if (res.status === true) {
      return res.data;
    } else {
      return [];
    }
  };

  const getTokenDetail = async (addr) => {
    const res = await tokenService.detail(addr);

    if (res.status === true) {
      return res.data;
    } else {
      return [];
    }
  };

  const startBumpAll = async (tokenMint) => {
    const res = await tokenService.startBump(tokenMint);
    return res;
  };

  const stopBumpAll = async (tokenMint) => {
    const res = await tokenService.stopBump(tokenMint);
    return res;
  };

  return {
    addNewToken,
    getTokenList,
    getTokenDetail,
    startBumpAll,
    stopBumpAll,
  };
};
