import React from "react";

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer>
        <p>&copy; 2024 AP3X BumperBot. All rights reserved.</p>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
