import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import SearchIcon from "../../component/Icons/SearchIcon";
import ToggleIcon from "../../component/Icons/ToggleIcon";
import Balance from "../../component/Balance/Balance";
import BumpCard from "../../component/BumpCard/BumpCard";
import NewWalletCard from "../../component/BumpCard/NewWalletCard";
import AddTokenModal from "../../component/AddTokenModal/AddTokenModal";
import Loading from "../../component/Loading/Loading";

import { useTokenAPI } from "../../hooks/useTokenAPI";
import CloseIcon from "../../component/Icons/CloseIcon";

const Token = (props) => {
  const { getTokenList, getTokenDetail, startBumpAll, stopBumpAll } =
    useTokenAPI();

  const [keyword, setKeyword] = useState("");

  const [token, setToken] = useState(null);
  const [tokenDetail, setTokenDetail] = useState({});

  const [tokens, setTokens] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);

  const [walletLoadingStatus, setWalletLoadingStatus] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(true);

  const fetchTokens = async () => {
    const res = await getTokenList("");
    setTokens(res);
    setFilteredTokens(res);
    setLoadingStatus(false);
  };

  const fetchWallets = async () => {
    const res = await getTokenDetail(token.mintAddr);
    setTokenDetail(res);
    setWalletLoadingStatus(false);
  };

  const onKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const onChooseToken = (t) => {
    if (t !== token) {
      setWalletLoadingStatus(true);
      setToken(t);
    }
  };

  const onAddNewToken = () => {
    fetchTokens();
  };

  const onAddNewWallet = () => {
    fetchWallets();
  };

  const onStartBumpAll = async () => {
    if (token === null) return;
    const res = await startBumpAll(token.mintAddr);

    if (res.status === true) {
      fetchWallets();
      toast.success("Bump all started successfully!");
    } else {
      toast.error(res.error);
    }
  };

  const onStopBumpAll = async () => {
    if (token === null) return;
    const res = await stopBumpAll(token.mintAddr);

    if (res.status === true) {
      fetchWallets();
      toast.success("Bump all stopped!");
    } else {
      toast.error(res.error);
    }
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  useEffect(() => {
    // Filter tokens based on the keyword
    if (tokens !== null) {
      const filtered = tokens.filter((token) =>
        token.name.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredTokens(filtered);

      if (filtered.length > 0 && token === null) {
        setToken(filtered[0]);
      }
    }
  }, [keyword, tokens]);

  useEffect(() => {
    if (token !== null && token.mintAddr.length === 44) {
      fetchWallets();
    }
  }, [token]);

  return (
    <div className="main-wrapper">
      <nav className="navbar navbar-expand-lg bg-light p-0">
        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="inner-menu-panel">
            <button
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              className="inner-close d-block d-lg-none"
            >
              <CloseIcon />
            </button>
            <div className="search-box">
              {/* <i className="iconsax" data-icon="search-normal-2">
                <SearchIcon />
              </i> */}
              <input
                type="text"
                className="form-control"
                placeholder="Search here.."
                onChange={onKeywordChange}
                value={keyword}
              />
            </div>
            <ul className="inner-links-list" id="innerLink">
              {filteredTokens.map((t) => (
                <li
                  className={
                    token !== null && t.mintAddr === token.mintAddr
                      ? "active"
                      : ""
                  }
                  key={t.mintAddr}
                  onClick={() => onChooseToken(t)}
                >
                  <Link to="/token" className="d-flex align-items-center">
                    <img src={t.imageUri} width="30px" />
                    <div className="ms-3">
                      <span>{t.name}</span>
                      {/* <p className="mb-0">{t.symbol}</p> */}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="px-3">
              <AddTokenModal onAddNewToken={onAddNewToken} />
            </div>
          </div>
        </div>
      </nav>
      <div className="chat-header d-flex flex-column flex-md-row ">
        <div className="d-flex align-items-center gap-2">
          <button
            className="navbar-toggler d-md-none d-block"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainnavbarNav"
            aria-controls="mainnavbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax">
              <ToggleIcon />
            </i>
          </button>

          {token !== null && (
            <div className="d-flex align-items-center">
              {/* <img src={token.imageUri} width="30px" /> */}
              <h3 className="ms-3 ">
                {token.name} ({token.symbol})
              </h3>
            </div>
          )}
        </div>
        <Balance />
      </div>
      <div className="main-chat px-5">
        {walletLoadingStatus === false && (
          <div className="row">
            {tokenDetail.length > 0 &&
              tokenDetail.map((wallet) => (
                <div
                  key={wallet.address}
                  className="col-12 col-sm-6 col-md-4 p-2"
                >
                  <BumpCard wallet={wallet} />
                </div>
              ))}

            {token !== null && (
              <div className="col-12 col-sm-6 col-md-4 p-2">
                <NewWalletCard
                  tokenAddr={token.mintAddr}
                  onAddNewWallet={onAddNewWallet}
                />
              </div>
            )}
          </div>
        )}
        <div className="msger-inputarea">
          <button
            className="navbar-toggler d-lg-none d-block msger-send-btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify">
              <ToggleIcon />
            </i>
          </button>
          {token !== null && (
            <>
              <button
                className="bump-start-btn msger-send-btn"
                onClick={onStartBumpAll}
              >
                Start Bump
              </button>
              <button
                className="bump-stop-btn msger-send-btn"
                onClick={onStopBumpAll}
              >
                Stop Bump
              </button>
            </>
          )}
        </div>
      </div>
      {loadingStatus && <Loading />}
    </div>
  );
};

export default Token;
