import { userService } from "../services";

export const useUserAPI = () => {
  const getInfo = async () => {
    const res = await userService.info();
    return res;
  };

  const getReferralHistory = async () => {
    const res = await userService.referral();
    return res;
  };

  const getPointHistory = async () => {
    const res = await userService.point();
    return res;
  };

  const getWalletData = async () => {
    const res = await userService.balance();
    return res;
  };

  const updateInfo = async (uInfo) => {
    const res = await userService.update(uInfo);
    return res;
  };

  const withdraw = async () => {
    const res = await userService.withdraw();
    return res;
  };

  const withdrawOut = async (wInfo) => {
    const res = await userService.withdrawOut(wInfo);
    return res;
  };

  return {
    getInfo,
    getReferralHistory,
    getPointHistory,
    getWalletData,
    updateInfo,
    withdraw,
    withdrawOut,
  };
};
