import React, { useState, useRef } from "react";
import { toast } from "react-toastify";

import AddIcon from "../Icons/AddIcon";
import CloseIcon from "../Icons/CloseIcon";
import Loading from "../Loading/Loading";

import { useTokenAPI } from "../../hooks/useTokenAPI";

const AddTokenModal = (props) => {
  const { addNewToken } = useTokenAPI();
  const { onAddNewToken } = props;

  const closeRef = useRef(null);

  const [tokenAddr, setTokenAddr] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);

  const onChangeTokenAddr = (e) => {
    setTokenAddr(e.target.value);

    if (e.target.value.length === 44) {
    }
  };

  const onAdd = async () => {
    if (tokenAddr.length === 44) {
      setLoadingStatus(true);
      const res = await addNewToken(tokenAddr);
      if (res.status === true) {
        toast.success("Token Add Succeed");

        setTokenAddr("");
        onAddNewToken();
        closeRef.current.click();
      } else {
        toast.error(res.error);
      }
      setLoadingStatus(false);
    }
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="w-100 btn btn-theme btn-start d-inline-block nav-link"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        <i className="iconsax">
          <AddIcon />
        </i>{" "}
        Add Token
      </button>
      <div
        className="modal rating-modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Add New Token
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
              >
                <i className="iconsax">
                  <CloseIcon />
                </i>
              </button>
            </div>
            <div className="modal-body">
              <form className="auth-form">
                <div className="form-group">
                  <label className="form-label text-white">Token Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Input token address that you want to bump."
                    value={tokenAddr}
                    onChange={onChangeTokenAddr}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="modal-submit m-0"
                onClick={onAdd}
              >
                Add Token To List
              </button>
            </div>
          </div>
        </div>
      </div>
      {loadingStatus && <Loading />}
    </React.Fragment>
  );
};

export default AddTokenModal;
