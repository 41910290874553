import React from "react";

import "./Home.css";

import Hero from "./sections/Hero.js";
import About from "./sections/About.js";
import Feature from "./sections/Feature.js";
import Pricing from "./sections/Pricing.js";
import Contact from "./sections/Contact.js";

const Home = (props) => {
  return (
    <React.Fragment>
      <Hero />
      <Feature />
      <Pricing />
      <About />
      <Contact />
    </React.Fragment>
  );
};

export default Home;
