import React from "react";

const Pricing = (props) => {
  return (
    <React.Fragment>
      <section id="pricing" className="pricing">
        <h2>Pricing</h2>
        <div className="pricing-tiers">
          {/* <div className="tier">
            <h3>Basic</h3>
            <p>0.125 SOL per Token Listing</p>
            <ul>
              <li>Top Listing</li>
              <li>Basic Volume Boost</li>
            </ul>
            <a href="#signup" className="cta-button">
              Choose Plan
            </a>
          </div>
          <div className="tier">
            <h3>Pro</h3>
            <p>1 SOL for 10 Token Listings</p>
            <ul>
              <li>Top Listing</li>
              <li>Advanced Volume Boost</li>
              <li>Trending Domination</li>
            </ul>
            <a href="#signup" className="cta-button">
              Choose Plan
            </a>
          </div>
          <div className="tier">
            <h3>Enterprise</h3>
            <p>10 SOL for unlimited</p>
            <ul>
              <li>Customized Solutions</li>
              <li>Dedicated Support</li>
              <li>And More...</li>
            </ul>
            <a href="#signup" className="cta-button">
              Choose Plan
            </a>
          </div> */}
          <div className="tier">
            <h3>Basic</h3>
            <p>0.1 SOL per Token Listing</p>
            <p>0.1% of bump amount per bump</p>
            <ul>
              <li>Top Listing</li>
              <li>Basic Volume Boost</li>
            </ul>
            <a href="#signup" className="cta-button">
              Choose Plan
            </a>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Pricing;
