import { BACKEND_URL } from "../config";
import { AuthService } from "./auth.service";
import { TokenService } from "./token.service";
import { WalletService } from "./wallet.service";
import { UserService } from "./user.service";

export const authService = new AuthService(`${BACKEND_URL}/auth`);
export const userService = new UserService(`${BACKEND_URL}/user`);
export const tokenService = new TokenService(`${BACKEND_URL}/token`);
export const walletService = new WalletService(`${BACKEND_URL}/wallet`);
