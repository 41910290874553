import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useUserAPI } from "../../hooks/useUserAPI";

import ToggleIcon from "../../component/Icons/ToggleIcon";
import { toast } from "react-toastify";

const Setting = (props) => {
  const { getInfo, updateInfo, getWalletData, withdraw, withdrawOut } =
    useUserAPI();

  const [info, setInfo] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [pwd, setPwd] = useState("");
  const [cpwd, setCpwd] = useState("");
  const [waddress, setWaddress] = useState("");

  const fetchInfo = async () => {
    const res = await getInfo();
    if (res.status === true) setInfo(res.data);
  };

  const fetchWallet = async () => {
    const res = await getWalletData();
    if (res.status === true) setWallet(res.address);
  };

  const onUpdate = async () => {
    if (pwd !== cpwd) {
      toast.error("Password must be same with confirm password!");
    }
    const res = await updateInfo({
      username: info.username,
      email: info.email,
      password: pwd,
      referrerId: info._id,
    });

    if (res.status === true) {
      toast.success("Password was updated successfully!");
    } else {
      toast.error(res.error);
    }
  };

  const onCancel = async () => {
    setPwd("");
    setCpwd("");
  };

  const onClear = async () => {
    setWaddress("");
  };

  const onWithdraw = async () => {
    const res = await withdraw();

    if (res.status === true) {
      toast.success("Moved funds from bump wallets successfully!");
    } else {
      toast.error(res.error);
    }
  };

  const onWithdrawOut = async () => {
    const res = await withdrawOut({ withdrawAddress: waddress });

    if (res.status === true) {
      toast.success("Withdraw funds successfully!");
    } else {
      toast.error(res.error);
    }
  };

  useEffect(() => {
    fetchInfo();
    fetchWallet();
  }, []);

  return (
    <div className="main-wrapper p-0 ">
      <div className="fixed-header">
        <div className="d-flex align-items-center gap-2">
          <button
            className="navbar-toggler d-md-none d-block"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainnavbarNav"
            aria-controls="mainnavbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify">
              <ToggleIcon />
            </i>
          </button>

          <Link to="/token" className="logo-icon d-flex d-md-none">
            <img
              src="../assets/svg/logo-icon.svg"
              className="img-fluid"
              width="44px"
            />
          </Link>
          <h3>Settings</h3>
        </div>
      </div>
      <div className="main-section d-flex gap-4 flex-column section-scroll">
        <div className="container card p-0">
          <div className="card-body px-sm-4 px-3">
            <div className="my-account">
              <div className="user-main">
                <div className="user-option">
                  <h4>{info === null ? "---" : "@" + info.username}</h4>
                  <p>{info === null ? "---" : info.email}</p>
                  <p
                    className="text-danger cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        window.location.origin.toString() +
                          "/register?inviteCode=" +
                          info._id
                      );
                    }}
                  >
                    {info === null
                      ? "---"
                      : window.location.origin.toString() +
                        "/register?inviteCode=" +
                        info._id}
                  </p>
                  <hr />
                  <span className="">
                    Deposit Wallet : {wallet === null ? "---" : wallet}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container card p-0">
          <div className="card-header">
            <h3 className="text-white">
              Move Funds from Bump Wallets to Deposit Wallet
            </h3>
          </div>
          <div className="card-footer">
            <div className="setting-btn">
              <button className="select-plan btn-start" onClick={onWithdraw}>
                Move
              </button>
            </div>
          </div>
        </div>
        <div className="container card p-0">
          <div className="card-header">
            <h3 className="text-white">
              Withdraw Funds from Deposit Wallet to User Wallet
            </h3>
          </div>
          <div className="card-body px-sm-4 px-3">
            <div className="my-account">
              <form className="msger-inputarea mb-0">
                <div className="mb-3">
                  <label htmlFor="wallet_address" className="form-label">
                    Withdraw Address
                  </label>
                  <input
                    type="text"
                    className="msger-input"
                    id="wallet_address"
                    placeholder="Input Your Wallet Address"
                    value={waddress}
                    onChange={(e) => setWaddress(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="card-footer">
            <div className="setting-btn">
              <button className="select-plan btn-start" onClick={onWithdrawOut}>
                Withdraw
              </button>
              <button className="no-select-plan select-plan" onClick={onClear}>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="container card p-0">
          <div className="card-header">
            <h3 className="text-white">Change password</h3>
          </div>
          <div className="card-body px-sm-4 px-3">
            <div className="my-account">
              <form className="msger-inputarea mb-0">
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="firstname" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="msger-input"
                        id="firstname"
                        placeholder="*********"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="lastname" className="form-label">
                        Confirm password
                      </label>
                      <input
                        type="password"
                        className="msger-input"
                        id="lastname"
                        placeholder="*********"
                        value={cpwd}
                        onChange={(e) => setCpwd(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="card-footer">
            <div className="setting-btn">
              <button className="select-plan btn-start" onClick={onUpdate}>
                Update password
              </button>
              <button className="no-select-plan select-plan" onClick={onCancel}>
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
