import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuthAPI } from "../../hooks/useAuthAPI";

import Loading from "../../component/Loading/Loading";
import MailIcon from "../../component/Icons/MailIcon";

const Login = (props) => {
  const navigate = useNavigate();

  const { reset } = useAuthAPI();

  const [loadingStatus, setLoadingStatus] = useState(false);

  const [email, setEmail] = useState("");

  const onReset = async () => {
    if (email === "") {
      toast.warning("Please input email!");
      return;
    }

    setLoadingStatus(true);
    const res = await reset(email);

    if (res.status === true) {
      toast.success("Password reset succeed");
      navigate("/login");
    } else {
      toast.error(res.error);
    }
    setLoadingStatus(false);
  };

  return (
    <React.Fragment>
      <section className="login-section section-scroll">
        <div className="text-center mb-5">
          <Link to="/">
            <img src="../assets/images/logo.png" height="50px" />
          </Link>
        </div>
        <div className="row m-0">
          <div className="col-xxl-4 col-lg-5 mx-auto p-0">
            <div className="login-box">
              <div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabIndex="0"
                  >
                    <div className="auth-form">
                      <div className="mb-3 form-group">
                        <i className="iconsax" data-icon="mail">
                          <MailIcon />
                        </i>
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          placeholder="Enter your email"
                          className="form-control"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <button
                        data-cursor="pointer"
                        className="btn-solid w-100 text-center mt-3"
                        onClick={onReset}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loadingStatus && <Loading />}
    </React.Fragment>
  );
};

export default Login;
