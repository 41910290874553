import { walletService } from "../services";

export const useWalletAPI = () => {
  const addNewWallet = async (addr) => {
    const res = await walletService.add(addr);
    return res;
  };

  const getWalletList = async () => {
    const res = await walletService.list();
    return res;
  };

  const startBump = async (
    tokenMint,
    bumpAddress,
    bumpInterval,
    bumpAmount
  ) => {
    const res = await walletService.startBump(
      tokenMint,
      bumpAddress,
      bumpInterval,
      bumpAmount
    );

    return res;
  };

  const stopBump = async (tokenMint, bumpAddress) => {
    const res = await walletService.stopBump(tokenMint, bumpAddress);

    return res;
  };

  return { addNewWallet, getWalletList, startBump, stopBump };
};
