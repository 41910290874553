import axios from "axios";

export class AuthService {
  constructor(url) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Time out!",
    });
  }

  signup = (username, email, password, referral) => {
    return this.instance
      .post("/register", {
        username,
        email,
        password,
        referral,
      })
      .then((res) => {
        return res.data;
      });
  };

  reset = (email) => {
    return this.instance
      .post("/reset", {
        emailOrUsername: email,
      })
      .then((res) => {
        return res.data;
      });
  };

  login = (emailOrUsername, password) => {
    return this.instance
      .post("/login", {
        emailOrUsername,
        password,
      })
      .then((res) => {
        return res.data;
      });
  };
}
