import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useUserAPI } from "../../hooks/useUserAPI";

import SearchIcon from "../../component/Icons/SearchIcon";
import ToggleIcon from "../../component/Icons/ToggleIcon";

const Referral = (props) => {
  const { getReferralHistory } = useUserAPI();

  const [keyword, setKeyword] = useState("");

  const [history, setHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);

  const fetchHistory = async () => {
    const res = await getReferralHistory();

    if (res.status === true) {
      setHistory(res.data);
    } else {
      toast.error(res.error);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    if (history !== null) {
      const filtered = history.filter((referral) =>
        referral.name.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredHistory(filtered);
    }
  }, [keyword, history]);

  return (
    <div className="main-wrapper p-0">
      <div className="fixed-header">
        <div className="d-flex align-items-center gap-2">
          <button
            className="navbar-toggler d-md-none d-block"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainnavbarNav"
            aria-controls="mainnavbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify">
              <ToggleIcon />
            </i>
          </button>

          <Link to="/token" className="logo-icon d-flex d-md-none">
            <img
              src="../assets/svg/logo-icon.svg"
              className="img-fluid"
              width="44px"
            />
          </Link>
          <h3>Referral</h3>
        </div>
      </div>
      <div className="main-section">
        <div className="container card p-0">
          <div className="card-header">
            <h3 className="text-white">Referral History</h3>
            {/* <form className="auth-form d-none d-md-block">
              <div className="form-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search here"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <i className="iconsax">
                  <SearchIcon />
                </i>
              </div>
            </form> */}
          </div>
          <div className="card-body px-sm-4 px-3">
            <ul className="history-sec">
              {history.length > 0 ? (
                history.map((ref) => (
                  <li className="history-main" key={ref._id}>
                    <div className="history-detail text-truncate">
                      <i className="iconsax" data-icon="message-text"></i>
                      <div>
                        <p>{ref.coinName}</p>
                        <p className="d-sm-none d-inline-block">
                          +{ref.amount} SOL
                        </p>
                      </div>
                    </div>
                    <div className="history-time d-sm-flex d-none">
                      <ul>
                        <li>{ref.username}</li>
                        <li>+{ref.amount} SOL</li>
                      </ul>
                    </div>
                  </li>
                ))
              ) : (
                <li className="history-main">
                  <div className="d-block w-100 my-5 text-white text-center">
                    No Referral History
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
