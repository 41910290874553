import React from "react";

const LoadingIcon = (props) => {
  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{
          shapeRendering: "auto",
          display: "block",
        }}
        width="100"
        height="100"
      >
        <g data-idx="1">
          <circle
            strokeDasharray="164.93361431346415 56.97787143782138"
            r="35"
            strokeWidth="10"
            stroke="#fe3839"
            fill="none"
            cy="50"
            cx="50"
            data-idx="2"
          ></circle>
          <g data-idx="4"></g>
        </g>
      </svg>
    </React.Fragment>
  );
};

export default LoadingIcon;
