import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useUserAPI } from "../../hooks/useUserAPI";

import ToggleIcon from "../../component/Icons/ToggleIcon";

const AWARD_TYPE = {
  1: "Refer User",
  2: "Add Token",
  3: "Bump Token",
};

const History = (props) => {
  const { getPointHistory } = useUserAPI();

  const [point, setPoint] = useState(0);
  const [history, setHistory] = useState([]);

  const fetchHistory = async () => {
    const res = await getPointHistory();
    if (res.status === true) {
      setHistory(res.data.reverse());

      let points = res.data.map(({ pointAdd }) => pointAdd);
      setPoint(points.reduce((a, b) => a + b, 0));
    } else {
      toast.error(res.error);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="main-wrapper p-0">
      <div className="fixed-header">
        <div className="d-flex align-items-center gap-2">
          <button
            className="navbar-toggler d-md-none d-block"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainnavbarNav"
            aria-controls="mainnavbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify">
              <ToggleIcon />
            </i>
          </button>
          <Link to="/token" className="logo-icon d-flex d-md-none">
            <img
              src="../assets/svg/logo-icon.svg"
              className="img-fluid"
              width="44px"
            />
          </Link>
          <h3>Point</h3>
        </div>
      </div>
      <div className="main-section">
        <div className="container card p-0">
          <div className="card-header">
            <h3 className="text-white">Point History</h3>
            <p className="mb-0">Total Point : {point}</p>
          </div>
          <div
            className="card-body px-sm-4 px-3"
            style={{ maxHeight: "calc(100vh - 220px)", overflow: "auto" }}
          >
            <ul className="history-sec">
              {history.length > 0 ? (
                history.map((pt) => (
                  <li className="history-main" key={pt._id}>
                    <div className="history-detail text-truncate">
                      <i className="iconsax" data-icon="message-text"></i>
                      <div>
                        <p>{AWARD_TYPE[pt.awardType]}</p>
                        <p className="d-sm-none d-inline-block">
                          + {pt.pointAdd} <small>point</small>
                        </p>
                      </div>
                    </div>
                    <div className="history-time d-sm-flex d-none">
                      <ul>
                        <li>{pt.param}</li>
                        <li>
                          + {pt.pointAdd} <small>point</small>
                        </li>
                      </ul>
                    </div>
                  </li>
                ))
              ) : (
                <li className="history-main">
                  <div className="d-block w-100 my-5 text-white text-center">
                    No Point History
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
