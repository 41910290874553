import React from "react";
import { Outlet } from "react-router-dom";

import NavBar from "./NavBar";

const BoardLayout = (props) => {
  return (
    <React.Fragment>
      <section className="chatting-wrapper pt-0">
        <NavBar />
        <div className="tab-content">
          <Outlet />
        </div>
      </section>
    </React.Fragment>
  );
};

export default BoardLayout;
