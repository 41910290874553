import React from "react";
import LoadingIcon from "../Icons/LoadingIcon";

const Loading = (props) => {
  return (
    <React.Fragment>
      <div className="loading-container">
        <LoadingIcon />
      </div>
    </React.Fragment>
  );
};

export default Loading;
